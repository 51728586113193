<template>
    <div class="main_wrapper">
        <div class="modal_wrapper" v-if="$parent.modal && pelicula">
            <div class="modal_window">
                <section class="content_pannel edit_pannel">
                    <header class="pannel_header">
                        <h3>Peliculas<span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></span>Editar</h3>
                        <div class="close_pannel" @click="close()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                        </div>
                    </header>
                    <div class="content_inner">
                        <div class="grid grid_pad grid_wrap">
                            <div class="grid_col grid_col_1_1 form_block">
                                <div class="toggle_wrapper">
                                    <span>Inactivo</span>
                                    <label class="toggle">
                                        <input type="checkbox" v-model="pelicula.active">
                                        <span class="toggle_slider"></span>
                                    </label>
                                    <span>Activo</span>
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Título</label>
                                <div class="input_block">
                                    <input type="text" v-model="pelicula.title">
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>URL (El texto que identificara la pelicula)</label>
                                <div class="input_block">
                                    <input type="text" v-model="pelicula.url">
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_1 separator"></div>
                            <div class="grid_col grid_col_1_1 form_block">
                                <label>Sinopsis</label>
                                <div class="input_block">
                                    <textarea v-model="pelicula.description"></textarea>
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_4 form_block">
                                <label>Duración</label>
                                <div class="input_block">
                                    <input type="number" v-model="pelicula.duration">
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_4 form_block">
                                <label>Año</label>
                                <div class="input_block">
                                    <input type="number" v-model="pelicula.year">
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_4 form_block">
                                <label>País</label>
                                <div class="input_block">
                                    <input type="text" v-model="pelicula.country">
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_4 form_block">
                                <label>Nímero de ICAA</label>
                                <div class="input_block">
                                    <input type="text" v-model="pelicula.icaa">
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_1 separator"></div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Actores</label>
                                <div class="input_block">
                                    <select v-model="selActor">
                                        <option :value="null" selected disabled>Elige una opción</option>
                                        <option v-for="(thing, index) in actores" :key="index" :value="thing">{{thing.name}}</option>
                                    </select>
                                </div>
                                <div class="tag_list">
                                    <span class="etiqueta" v-for="(actor, index) in pelicula.actors" :key="index" @click="del(1, actor)">{{actor.name}} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Categorias</label>
                                <div class="input_block">
                                    <select v-model="selCategoria">
                                        <option :value="null" selected disabled>Elige una opción</option>
                                        <option v-for="(thing, index) in categorias" :key="index" :value="thing">{{thing.name}}</option>
                                    </select>
                                </div>
                                <div class="tag_list">
                                    <span class="etiqueta" v-for="(categoria, index) in pelicula.categories" :key="index" @click="del(2, categoria)">{{categoria.name}} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Director</label>
                                <div class="input_block">
                                    <select v-model="selDirector">
                                        <option :value="null" selected disabled>Elige una opción</option>
                                        <option v-for="(thing, index) in directores" :key="index" :value="thing">{{thing.name}}</option>
                                    </select>
                                </div>
                                <div class="tag_list">
                                    <span class="etiqueta" v-for="(director, index) in pelicula.directors" :key="index" @click="del(3, director)">{{director.name}} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Productora</label>
                                <div class="input_block">
                                    <select v-model="selProductora">
                                        <option :value="null" selected disabled>Elige una opción</option>
                                        <option v-for="(thing, index) in productoras" :key="index" :value="thing">{{thing.name}}</option>
                                    </select>
                                </div>
                                <div class="tag_list">
                                    <span class="etiqueta" v-for="(productora, index) in pelicula.producers" :key="index" @click="del(4, productora)">{{productora.name}} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Familia</label>
                                <div class="input_block">
                                    <select v-model="selFamilia">
                                        <option :value="null" selected disabled>Elige una opción</option>
                                        <option v-for="(thing, index) in familias" :key="index" :value="thing">{{thing.name}}</option>
                                    </select>
                                </div>
                                <div class="tag_list">
                                    <span class="etiqueta" v-for="(familia, index) in pelicula.families" :key="index" @click="del(5, familia)">{{familia.name}} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_4 form_block">
                                <label>Publicado el</label>
                                <div class="input_block">
                                    <input type="datetime-local" v-model="pelicula.actived_at">
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_1 separator bordered">
                                <span></span>
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <div class="toggle_wrapper">
                                    <span>Inactivo</span>
                                    <label class="toggle">
                                        <input type="checkbox" v-model="pelicula.active">
                                        <span class="toggle_slider"></span>
                                    </label>
                                    <span>Activo</span>
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_2">
                                <div class="grid grid_pad">
                                    <div class="grid_col grid_col_1_2 form_block" @click="close()">
                                        <button class="btn med grey ko graphic graphic_right center wide">Cancelar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg></button>
                                    </div>
                                    <div class="grid_col grid_col_1_2 form_block" @click="save()">
                                        <button class="btn med grey graphic graphic_right center wide">Guardar cambios<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13 3h2.996v5h-2.996v-5zm11 1v20h-24v-24h20l4 4zm-17 5h10v-7h-10v7zm15-4.171l-2.828-2.829h-.172v9h-14v-9h-3v20h20v-17.171z"/></svg></button>
                                    </div>
                                    <div v-if="this.pelicula.id" class="grid_col grid_col_1_2 form_block" @click="eliminar()">
                                        <button class="btn med grey ko graphic graphic_right center wide">Eliminar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"/></svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <main class="content_wrapper">
            <div class="inner">
                <section class="page_header">
                    <h2>Películas</h2>
                    <div class="page_header_nav">
                        <div class="btn med grey graphic_right" @click="nuevo()">
                            <span>Agregar nuevo</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                        </div>
                    </div>
                </section>
                <section class="content_pannel">
                    <header class="pannel_header">
                        <h3>Listado de películas</h3>
                    </header>
                    <div class="content_inner">
                        <div class="grid grid_pad grid_wrap">
                            <div class="grid_col grid_col_1_1 form_block">
                                <VityTable 
                                    ref="vtable"
                                    v-if="url"
                                    :columns="columns"
                                    :url="url+'films'"
                                    :orderBy="{
                                        column: 1,
                                        type: 1
                                    }"
                                    :swipeResult="{
                                        column: [2],
                                        data:{
                                            '0': 'NO',
                                            '1': 'SI'
                                        }
                                    }"
                                    :params="{admin: $store.state.user}"
                                    tableFooter
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
import axios from 'axios';
import VityTable from '@/components/VityUtils/vitytables/VityTables.vue';

export default {
    name: 'Peliculas',
    components:{
        VityTable
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'Titulo',
                    dbName: 'title',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Publicada',
                    center: true,
                    dbName: 'actived_at',
                    orderable: true,
                    searchable: true,
                    date: true
                },
                {
                    name: 'Activo',
                    center: true,
                    dbName: 'active',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    center: true,
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/peliculas/TPLData"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.127 22.564l-7.126 1.436 1.438-7.125 5.688 5.689zm-4.274-7.104l5.688 5.689 15.46-15.46-5.689-5.689-15.459 15.46z"/></svg></a>',
                    reference: 'id'
                },
            ],
            url: process.env.VUE_APP_URL,
            pelicula: null,

            actores: [],
            categorias: [],
            directores: [],
            productoras: [],
            familias: [],

            selActor: null,
            selCategoria: null,
            selDirector: null,
            selProductora: null,
            selFamilia: null,
        }
    },
    methods: {
        init: function(){
            if(this.$route.params.id)
                this.load(this.$route.params.id);
            else
                this.pelicula = {}
        },
        load: function(id){
            axios
                .get(process.env.VUE_APP_URL+`films/${id}`, {
                    params: {
                        films: 1,
                        admin: this.$store.state.user,
                        categories: 1,
                        families: 1,
                        actors: 1,
                        directors: 1,
                        producers: 1,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.pelicula = rs.data;
                        this.$parent.modal = true;
                        this.pelicula.actived_at = this.pelicula.actived_at.replace(' ', 'T');
                        this.pelicula.actived_at = this.pelicula.actived_at.replace(/\.[0]+Z/gm, '');

                        this.getActores();
                        this.getCategorias();
                        this.getDirectores();
                        this.getProductoras();
                        this.getFamilias();
                    }
                    else{
                        this.$parent.error = {
                            type: 2,
                            msg: rs.msg
                        };
                    }
                })
                .catch(error => {
                    this.$parent.error = {
                        type: 2,
                        msg: 'Algo ha fallado durante la conexion'
                    };
                })
        },
        save: function(){
            let errors = '';
            if(!this.pelicula.title)
                errors += 'Falta el titulos<br>';
            if(!this.pelicula.url)
                errors += 'Falta la URL<br>';
            if(!this.pelicula.description)
                errors += 'Falta la sinopsis<br>';
            if(!this.pelicula.duration)
                errors += 'Falta la duracion<br>';
            if(!this.pelicula.year)
                errors += 'Falta el año<br>';
            if(!this.pelicula.country)
                errors += 'Falta el pais<br>';
            if(!this.pelicula.icaa)
                errors += 'Falta el icaa<br>';

            if(errors != ''){
                this.$parent.error = {
                    type: 2,
                    msg: errors
                };
                return;
            }

            axios
                .post(process.env.VUE_APP_URL+`films`+(this.pelicula.id ? `/${this.pelicula.id}` : ``), {
                    admin: this.$store.state.user,
                    active: this.pelicula.active,
                    title: this.pelicula.title,
                    url: this.pelicula.url,
                    description: this.pelicula.description,
                    duration: this.pelicula.duration,
                    year: this.pelicula.year,
                    country: this.pelicula.country,
                    icaa: this.pelicula.icaa,
                    actors: this.pelicula.actors,
                    categories: this.pelicula.categories,
                    directors: this.pelicula.directors,
                    producers: this.pelicula.producers,
                    families: this.pelicula.families,
                    actived_at: this.pelicula.actived_at
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.$parent.error = {
                            type: 1,
                            msg: 'Guardado correctamente'
                        };
                        if(this.pelicula.id)
                            this.load(this.pelicula.id);
                        else
                            this.$router.push(`/peliculas/${rs.data}`);

                        this.$refs.vtable.getDatos();
                    }
                    else{
                        this.$parent.error = {
                            type: 2,
                            msg: rs.msg
                        };
                    }
                })
                .catch(error => {
                    this.$parent.error = {
                        type: 2,
                        msg: 'Algo ha salido mal'
                    };
                })
        },
        eliminar: function(){
            if(!this.pelicula.id)
                return false;
            if(confirm("Seguro que quieres eliminarlo?")){
                axios
                    .post(process.env.VUE_APP_URL+`delfilms/${this.pelicula.id}`,{
                        admin: this.$store.state.user
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.$parent.error = {
                                type: 1,
                                msg: 'Eliminado correctamente'
                            };
                            this.close();
                            this.$refs.vtable.getDatos()
                        }
                        else{
                            this.$parent.error = {
                                type: 2,
                                msg: rs.msg
                            };
                        }
                    })
                    .catch(error => {
                        this.$parent.error = {
                            type: 2,
                            msg: 'Algo ha salido mal'
                        };
                    })
            }
        },
        close: function(){
            this.$parent.modal = false;
            this.pelicula = null;
            this.$router.push('/peliculas');
        },
        getActores: function(){
            axios
                .get(process.env.VUE_APP_URL+`actors`, {
                    params: {
                        admin: this.$store.state.user,
                        'orderBy[column]': 'name',
                        'orderBy[order]': 'asc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.actores = rs.data;
                        this.pelicula.actors.forEach(element => {
                            this.actores.splice(this.actores.findIndex(el => el.id == element.id), 1);
                        });
                    }
                })
        },
        getCategorias: function(){
            axios
                .get(process.env.VUE_APP_URL+`categories`, {
                    params: {
                        admin: this.$store.state.user,
                        'orderBy[column]': 'name',
                        'orderBy[order]': 'asc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.categorias = rs.data;
                        this.pelicula.categories.forEach(element => {
                            this.categorias.splice(this.categorias.findIndex(el => el.id == element.id), 1);
                        });
                })
        },
        getDirectores: function(){
            axios
                .get(process.env.VUE_APP_URL+`directors`, {
                    params: {
                        admin: this.$store.state.user,
                        'orderBy[column]': 'name',
                        'orderBy[order]': 'asc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.directores = rs.data;
                        this.pelicula.directors.forEach(element => {
                            this.directores.splice(this.directores.findIndex(el => el.id == element.id), 1);
                        });
                })
        },
        getProductoras: function(){
            axios
                .get(process.env.VUE_APP_URL+`producers`, {
                    params: {
                        admin: this.$store.state.user,
                        'orderBy[column]': 'name',
                        'orderBy[order]': 'asc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.productoras = rs.data;
                        this.pelicula.producers.forEach(element => {
                            this.productoras.splice(this.productoras.findIndex(el => el.id == element.id), 1);
                        });
                })
        },
        getFamilias: function(){
            axios
                .get(process.env.VUE_APP_URL+`families`, {
                    params: {
                        admin: this.$store.state.user,
                        'orderBy[column]': 'name',
                        'orderBy[order]': 'asc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.familias = rs.data;
                        this.pelicula.families.forEach(element => {
                            this.familias.splice(this.familias.findIndex(el => el.id == element.id), 1);
                        });
                })
        },
        del: function(type, obj){
            switch(type){
                case 1:
                    this.pelicula.actors.splice(this.pelicula.actors.findIndex(el => el.id == obj.id), 1);
                    this.actores.push(obj);
                    this.getActores();
                    break;
                case 2:
                    this.pelicula.categories.splice(this.pelicula.categories.findIndex(el => el.id == obj.id), 1);
                    this.categorias.push(obj);
                    this.getCategorias();
                    break;
                case 3:
                    this.pelicula.directors.splice(this.pelicula.directors.findIndex(el => el.id == obj.id), 1);
                    this.directores.push(obj);
                    this.getDirectores();
                    break;
                case 4:
                    this.pelicula.producers.splice(this.pelicula.producers.findIndex(el => el.id == obj.id), 1);
                    this.productoras.push(obj);
                    this.getProductoras();
                    break;
                case 5:
                    this.pelicula.families.splice(this.pelicula.families.findIndex(el => el.id == obj.id), 1);
                    this.familias.push(obj);
                    this.getFamilias();
                    break;
            }
        },
        nuevo: function(){
            this.pelicula = {
                actors: [],
                categories: [],
                directors: [],
                producers: [],
                families: [],
            };
            this.getActores();
            this.getCategorias();
            this.getDirectores();
            this.getProductoras();
            this.getFamilias();
            this.$parent.modal = true;
        }
    },
    created: function(){
        this.init();
    },
    watch: {
        $route: function(){
            this.init();
        },
        selActor: function(newval, oldval){
            if(!newval)
                return;
            this.pelicula.actors.push(newval);
            this.actores.splice(this.actores.findIndex(el => el.id == newval.id), 1);
            this.selActor = null;
        },
        selCategoria: function(newval, oldval){
            if(!newval)
                return;
            this.pelicula.categories.push(newval);
            this.categorias.splice(this.categorias.findIndex(el => el.id == newval.id), 1);
            this.selCategoria = null;
        },
        selDirector: function(newval, oldval){
            if(!newval)
                return;
            this.pelicula.directors.push(newval);
            this.directores.splice(this.directores.findIndex(el => el.id == newval.id), 1);
            this.selDirector = null;
        },
        selProductora: function(newval, oldval){
            if(!newval)
                return;
            this.pelicula.producers.push(newval);
            this.productoras.splice(this.productoras.findIndex(el => el.id == newval.id), 1);
            this.selProductora = null;
        },
        selFamilia: function(newval, oldval){
            if(!newval)
                return;
            this.pelicula.families.push(newval);
            this.familias.splice(this.familias.findIndex(el => el.id == newval.id), 1);
            this.selFamilia = null;
        },
    }
}
</script>
